import React from 'react';
import PropTypes from 'prop-types';
import { Link as GatsbyLink } from 'gatsby';
import { FaCalendarAlt, FaLaptopCode, FaUsers } from 'react-icons/fa';
import {
  Circle, Container, Flex, Heading, Icon, Link, Stack, Text,
} from '@chakra-ui/react';
import Layout from '../components/layout';
import data from '../data.json';
import homeBackground from '../assets/home_background.jpg';

// import Defaut from '../components/index/Default';
// import Fase2 from '../components/index/Fase2'

const IconDesc = ({ icon, title, subtitle }) => (
  <Flex
    alignItems="center"
    direction="column"
    my={6}
    mx={10}
  >
    <Circle
      borderColor="black"
      borderWidth={3}
    >
      <Icon
        as={icon}
        boxSize={20}
        color="primary"
        p={4}
      />
    </Circle>
    <Heading as="h2">{title}</Heading>
    <Heading as="h3" size="md">{subtitle}</Heading>
  </Flex>
);

IconDesc.propTypes = {
  icon: PropTypes.func.isRequired,
  title: PropTypes.number.isRequired,
  subtitle: PropTypes.string.isRequired,
};

const Home = ({ location }) => (
  <Layout location={location.pathname}>
    <Flex
      alignItems="center"
      bgImage={`linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.7)), url(${homeBackground})`}
      bgPos="bottom"
      bgSize="cover"
      color="white"
      direction="column"
      justify="center"
      minH="40vmin"
      p={5}
    >
      <Heading
        as="h1"
        maxW="40ch"
        mb={5}
        mx="auto"
        textAlign="center"
        size="lg"
        padding={4}
      >
        As incrições para a
        {' '}
        {data.home.edicao}
        {' '}
        Olimpíada Cearense de Informática estão abertas
      </Heading>
      <Stack
        align="stretch"
        direction="column"
        fontWeight="bold"
        mx="auto"
        spacing={3}
        w="fit-content"
      >
        <Link
          as={GatsbyLink}
          colorScheme="whiteAlpha"
          to="/regulamento"
          variant="outline"
        >
          Regulamento
        </Link>
        <Link
          colorScheme="whiteAlpha"
          href="https://www.youtube.com/playlist?list=PLh8xr5nCkSXyf9Y0wnRZ4d23AqoV90QhK"
          isExternal
          variant="outline"
        >
          Playlist preparatória para a OCI
        </Link>
      </Stack>
    </Flex>
    <Container my={3}>
      <Heading
        as="h1"
        mt={6}
        mb={3}
        size="xl"
      >
        A OCI
      </Heading>
      <Text textAlign="justify">
        {data.home.sobre}
      </Text>
      <Flex
        justifyContent="center"
        my={5}
        wrap="wrap"
      >
        <IconDesc icon={FaCalendarAlt} title={data.home.ano} subtitle="Início da OCI" />
        <IconDesc icon={FaUsers} title={data.home.alunos} subtitle="Alunos Inscritos" />
        <IconDesc icon={FaLaptopCode} title={data.home.modalidades} subtitle="Modalidades" />
      </Flex>
    </Container>
  </Layout>
);

Home.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }).isRequired,
};

export default Home;
